import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import PageLayout from '../components/Pages';
import { graphql } from 'gatsby';
import LocaleContext from 'context/LocaleProvider';

const Index = (props) => {
  const { selectedLocale } = useContext(LocaleContext);

  const edges = props?.data?.allContentfulPage?.edges?.filter(
    (edge) => edge?.node?.node_locale === selectedLocale
  );

  const articles = props?.data?.allContentfulArticleModule?.edges?.filter(
    (edge) => edge?.node?.node_locale === selectedLocale
  );

  const data = {
    data: {
      allContentfulPage: {
        edges,
      },
      allContentfulArticleModule: {
        edges: articles,
      },
    },
  };

  return (
    <PageLayout
      {...data}
      matchingPages={props?.data?.allContentfulPage.edges}
      location={props.location}
    />
  );
};

export default Index;

Index.propTypes = {
  location: PropTypes.object.isRequired,
};

export const pageQuery = graphql`
  query {
    allContentfulArticleModule {
      edges {
        node {
          id
          node_locale
          contentful_id
          title
          author
          tags
          page {
            handle
            parentPage {
              handle
            }
          }
          articleBody: body {
            raw
          }
          excerpt
          enDate: date(formatString: "MMMM DD YYYY")
          frDate: date(formatString: "DD MMMM YYYY")
          thumbnailImage {
            gatsbyImageData(aspectRatio: 1.75, quality: 100, layout: FULL_WIDTH)
            title
          }
        }
      }
    }
    allContentfulPage(
      filter: { handle: { regex: "/(?<name>^fr(?![/|a-z])|/(?![a-z]))/" } }
    ) {
      edges {
        node {
          contentful_id
          node_locale
          handle
          parentPage {
            handle
          }
          enableDarkHeader
          seo {
            node_locale
            contentful_id
            openGraphTitle
            openGraphDescription
            seoTitle
            seoDescription
            openGraphImage {
              file {
                url
              }
              title
            }
            seoSchema {
              address {
                addressCountry
                addressLocality
                addressRegion
                postalCode
                streetAddress
              }
              description
              email
              image
              legalName
              logo
              name
              url
              telephone
              _type
            }
          }
          modules {
            __typename
            ... on ContentfulGenericModule {
              id
              title
              body {
                raw
                references {
                  __typename
                  ... on ContentfulAsset {
                    id
                    contentful_id
                    gatsbyImageData(quality: 100, layout: FULL_WIDTH)
                    file {
                      url
                    }
                    title
                  }
                }
              }
            }
            ... on Contentful3DSliderModule {
              id
              contentful_id
              ThreeDSliderBody: body
              cta
              node_locale
              title
              secondaryTitle
              popupBody {
                raw
              }
              steps {
                body {
                  raw
                }
                hexColor
                contentful_id
                title
              }
            }
            ... on ContentfulAccordionModule {
              id
              contentful_id
              title
              secondaryTitle
              listItems
              tertiaryTitle
              tertiaryTitleListItems
              image {
                title
                gatsbyImageData(quality: 100, layout: FULL_WIDTH)
              }
            }
            ... on ContentfulAnodizingModule {
              id
              contentful_id
              title
              steps {
                title
                body {
                  raw
                }
                image {
                  title
                  gatsbyImageData(quality: 100, layout: FULL_WIDTH)
                }
              }
            }
            ... on ContentfulArticleModule {
              id
              contentful_id
              title
              author
              tags
              articleBody: body {
                raw
              }
              excerpt
              enDate: date(formatString: "MMMM DD YYYY")
              frDate: date(formatString: "DD MMMM YYYY")
              image {
                gatsbyImageData(quality: 100, layout: FULL_WIDTH)
                title
              }
              thumbnailImage {
                gatsbyImageData(quality: 100, layout: FULL_WIDTH)
                title
              }
            }
            ... on ContentfulComparativeTableModule {
              id
              contentful_id
              columns
              image {
                gatsbyImageData(quality: 100, layout: FULL_WIDTH)
                title
              }
              rows
              title
            }
            ... on ContentfulContactHeroModule {
              id
              title
              contentful_id
              address {
                lat
                lon
              }
              socialLinks {
                externalLink
                icon {
                  file {
                    url
                  }
                  title
                }
              }
              businessInformation {
                raw
              }
              businessDetails {
                raw
              }
              form {
                title
                cta
                ctaLink
                submit
                fileUpload
                fileError
                success {
                  raw
                }
                secondarySuccess
                inputs {
                  name
                  contentful_id
                  placeholder
                  type
                  selectOptions
                  error
                }
              }
            }
            ... on ContentfulContentAndImageModule {
              id
              contentful_id
              title
              icon {
                file {
                  url
                }
                title
              }
              darkMode
              image {
                gatsbyImageData(aspectRatio: 1.22, quality: 100, layout: FULL_WIDTH)
                title
              }
              contentAndImageBody: body {
                raw
              }
              internalLink {
                title
                externalLink
                internalLink {
                  handle
                  page {
                    parentPage {
                      handle
                    }
                  }
                }
              }
            }
            ... on ContentfulCtaBannerModule {
              id
              contentful_id
              ctaBannerBody: body
              title
              internalLink {
                title
                externalLink
                internalLink {
                  handle
                  parentPage {
                    handle
                  }
                }
              }
            }
            ... on ContentfulDocumentsModule {
              id
              contentful_id
              title
              cta
              documents {
                title
                body {
                  raw
                }
                image {
                  gatsbyImageData(quality: 100, layout: FULL_WIDTH)
                  title
                  file {
                    url
                  }
                }
              }
            }
            ... on ContentfulDropdownsModule {
              id
              contentful_id
              title
              dropdowns {
                contentful_id
                title
                body {
                  body
                }
                triggerOpen
                triggerClose
              }
            }
            ... on ContentfulEntryHeroModule {
              id
              contentful_id
              title
              EntryHeroBody: body
              caption
              scrollText
              scrollTextSecondary
              skip
              video {
                file {
                  url
                }
                title
              }
              videoYoutubeUrl
              textSlides {
                contentful_id
                title
                body {
                  raw
                }
              }
            }
            ... on ContentfulPostsGridModule {
              id
              contentful_id
              title
              filter
            }
            ... on ContentfulServicesListModule {
              id
              contentful_id
              title
              servicesListBody: body
              services {
                contentful_id
                title
                body {
                  raw
                }
                image {
                  gatsbyImageData(aspectRatio: 1, quality: 100, layout: FULL_WIDTH)
                  title
                }
              }
            }
            ... on ContentfulSliderModule {
              id
              contentful_id
              title
              subtitle
              body {
                body
              }
              lightdarkMode
              image {
                gatsbyImageData(quality: 100, layout: FULL_WIDTH)
                title
                file {
                  url
                }
              }
              link {
                title
                externalLink
                internalLink {
                  handle
                  parentPage {
                    handle
                  }
                }
              }
              drag
              cards {
                ... on ContentfulArticleModule {
                  id
                  contentful_id
                  title
                  author
                  tags
                  page {
                    handle
                    parentPage {
                      handle
                    }
                  }
                  articleBody: body {
                    raw
                  }
                  excerpt
                  enDate: date(formatString: "MMMM DD YYYY")
                  frDate: date(formatString: "DD MMMM YYYY")
                  desktopImage: image {
                    gatsbyImageData(aspectRatio: 1.77, quality: 100, layout: FULL_WIDTH)
                    title
                  }
                  mobileImage: image {
                    gatsbyImageData(aspectRatio: 1.25, quality: 100, layout: FULL_WIDTH)
                    title
                  }
                  thumbnailImage {
                    gatsbyImageData(quality: 100, layout: FULL_WIDTH)
                    title
                  }
                }
                ... on ContentfulCard1Module {
                  id
                  contentful_id
                  name
                  company
                  body {
                    body
                  }
                  logo {
                    file {
                      url
                    }
                    title
                  }
                  rating
                  link {
                    title
                    externalLink
                    internalLink {
                      page {
                        handle
                        parentPage {
                          handle
                        }
                      }
                    }
                  }
                }
                ... on ContentfulCard2Module {
                  id
                  contentful_id
                  content {
                    raw
                  }
                  projectImage: image {
                    gatsbyImageData(aspectRatio: 1.77, quality: 100, layout: FULL_WIDTH)
                    title
                  }
                  image {
                    gatsbyImageData(aspectRatio: 1.77, quality: 100, layout: FULL_WIDTH)
                    title
                  }
                  link {
                    __typename
                    ... on ContentfulLinkModule {
                      id
                      contentful_id
                      title
                      externalLink
                      internalLink {
                        handle
                        parentPage {
                          handle
                        }
                      }
                    }
                    ... on ContentfulSocialLinkModule {
                      id
                      contentful_id
                      icon {
                        file {
                          url
                        }
                        title
                      }
                      externalLink
                    }
                  }
                }
              }
            }
            ... on ContentfulSmallImagesHeroModule {
              id
              overline
              title
              images {
                contentful_id
                id
                gatsbyImageData(quality: 100, layout: FULL_WIDTH)
                title
              }
            }
            ... on ContentfulStatisticsModule {
              id
              contentful_id
              statisticBlocks {
                id
                contentful_id
                title
                body {
                  raw
                }
              }
            }
            ... on ContentfulVideoHeroModule {
              id
              contentful_id
              overline
              title
              secondaryTitle
              body {
                body
              }
              cta
              image {
                gatsbyImageData(quality: 100, layout: FULL_WIDTH)
                title
              }
              enableGradientOnImage
              videoYoutubeUrl
              video {
                file {
                  url
                }
                title
              }
            }
          }
        }
      }
    }
  }
`;
